#acceptance {
    height: 121px;
    width: 500px;
    background-color: #EFEFEF;
    margin-top: 17px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
} 
.acceptanceLogo {
    margin: 5px; 
    margin-top: 20px; 
    border: 1px solid #ADADAD;
}