html{
  overflow-x: hidden;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#stripePaymentFrame button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin: 8px 0;
}

#stripePaymentFrame button:hover {
  filter: contrast(115%);
}

#stripePaymentFrame button:disabled {
  opacity: 0.5;
  cursor: default;
}

#stripePaymentFrame button.isEmbedded {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  box-sizing: border-box;
  border-color: transparent;
  border-radius: 2px;
  background-color: #094f34;
  color: #fff;
  font-weight: 600;
  min-height: 46px;
  cursor: pointer;
  transition: background-color .3s ease-in;
  padding: 10px 16px;
  box-shadow: none;
  filter: none;
  opacity: 1;
}

#stripePaymentFrame button.isEmbedded:disabled {
  background-color: #f5f5f5;
  border: 1px solid #adadad;
  color: #adadad;
}

#stripePaymentFrame button.isEmbedded:hover:not([disabled]) {
  background-color: #0abe75;
  color: #fff;
}

#stripePaymentFrame button.isEmbedded:focus:not([disabled]) {
  background-color: #042518;
  color: hsl(0deg 0% 100% / 80%);
}

/* spinner/processing state, errors */
.spinner {
  border: 5px solid #fff;
  border-radius: 50%;
  border-top: 5px solid #5469d4;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.isEmbedded .spinner {
  border-top-color: #adadad;
}

.isEmbedded:not([disabled]) .spinner{
  border-color: #f5f5f5;
  border-top-color: #0abe75;
}

.isEmbedded:hover:not([disabled]) .spinner {
  border-top-color: #042518;
}

.errorMsg{
  color: red;
  margin:10px 0;
}

#wpg-iframe {
  width:100%;
  height: max-content;
  position: relative;
  transition: all 0.15s ease;
}

#wpg-iframe > iframe{
  border:0;
  width: inherit;
  height: inherit;
}

.StripePaymentIntentIframe{
  margin-top: 10px;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.errorMsg {
  padding: 15px;
  border: 1px solid;
  color: #eb1922;
  background: #ffe0e0;
}

/* Loading spinner start */
#loadingDiv {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #5469d4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.hide {
  opacity: 0 !important;
  max-height: 0;
  margin: 0;
  padding: 0;
  display: block;
  width: 0;
  z-index: -3;
  position: relative;
}

#turnstile-error {
  margin: 20px 0;
  color: red;
}

#verifyHumanText {
  color: #2F3032;
  font-size: 14px;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Lading spinner end*/

@media screen and (max-width: 480px) {
  #stripePaymentFrame button{
    position: relative;
  }
}

/* For Shipping Address Header */
.shippingAddressHeader {
  color: #30313d;
  line-height: 1.45;
}